import axios from "./index";

export default {
  getAll() {
    return axios.get("/v1/setting/tags");
  },
  create(data) {
    return axios.post("/v1/setting/tags", data);
  },
  update(data) {
    return axios.patch("/v1/setting/tags", data);
  },
};
