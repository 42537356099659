<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import appConfig from "@/app.config";
import tagSvc from "@/services/tag";
import { toast } from "vue3-toastify";

/**
 * Customers component
 */
export default {
  page: {
    title: "Tag",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Tag",
      items: [
        {
          text: "Setting",
          href: "#",
        },
        {
          text: "Tag",
          active: true,
        },
      ],
      columns: [
        {
          field: "code",
          hidden: true,
        },
        {
          label: "Name",
          field: "text",
        },
        {
          label: "Created Date",
          field: "createdAt",
        },
        {
          label: "Updated Date",
          field: "updatedAt",
        },
        {
          label: "Action",
          field: "button",
          html: true,
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      rows: [],
      showModalCreate: false,
      showModalEdit: false,
      submitted: false,
      tag: {
        code: "",
        title: "",
        text: "",
      },
      tagList: [],
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations: {
    tag: {
      text: {
        required: helpers.withMessage("tag is required", required),
      },
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      tagSvc.getAll().then((resp) => {
        if (resp.data.data === null) {
          return;
        }
        this.rows = resp.data.data;
        resp.data.data.forEach((tag) => {
          this.tagList.push(tag.text);
        });
      });
    },
    create() {
      this.tag.code = "";
      this.tag.text = "";
      this.submitted = false;
      this.showModalCreate = true;
    },
    handleSubmit() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        if (this.tagList.includes(this.tag.text)) {
          toast.error("this tag has already been used", {
            autoClose: localStorage.getItem("toastCountdown"),
          });
          return;
        }

        const data = {
          text: this.tag.text,
        };
        tagSvc
          .create(data)
          .then((resp) => {
            if (resp.status === 200) {
              toast.success("Created Successfully", {
                autoClose: localStorage.getItem("toastCountdown"),
              });
            } else {
              toast.error("Created Failed", {
                autoClose: localStorage.getItem("toastCountdown"),
              });
            }
          })
          .catch((err) => {
            toast.error(err, {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          })
          .finally(() => {
            this.get();
            this.showModalCreate = false;
            this.submitted = false;
          });
      }
    },
    edit(data) {
      this.tag.title = data.text;
      this.tag.code = data.code;
      this.tag.text = data.text;
      this.submitted = false;
      this.showModalEdit = true;
    },
    handleEditSubmit() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        if (
          this.tagList.includes(this.tag.text) &&
          this.tag.title !== this.tag.text
        ) {
          toast.error("this tag has already been used", {
            autoClose: localStorage.getItem("toastCountdown"),
          });
          return;
        }

        const data = {
          code: this.tag.code,
          text: this.tag.text,
        };
        tagSvc
          .update(data)
          .then((resp) => {
            if (resp.status === 200) {
              toast.success("Edited Successfully", {
                autoClose: localStorage.getItem("toastCountdown"),
              });
            } else {
              toast.error("Edited Failed", {
                autoClose: localStorage.getItem("toastCountdown"),
              });
            }
          })
          .catch((err) => {
            toast.error(err, {
              autoClose: localStorage.getItem("toastCountdown"),
            });
          })
          .finally(() => {
            this.get();
            this.showModalEdit = false;
            this.submitted = false;
          });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-12">
                <!-- MODAL CREATE -->
                <div class="text-sm-end">
                  <button
                    type="button"
                    class="btn btn-success btn-rounded mb-2 me-2"
                    @click="create"
                  >
                    <i class="mdi mdi-plus me-1"></i> New Tag
                  </button>
                  <b-modal
                    v-model="showModalCreate"
                    title="Add New Tag"
                    title-class="text-black font-18"
                    body-class="p-3"
                    hide-footer
                    no-close-on-backdrop
                  >
                    <form @submit.prevent="handleSubmit">
                      <div class="row">
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="text">Name</label>
                            <input
                              id="text"
                              v-model="tag.text"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && v$.tag.text.$error,
                              }"
                            />

                            <div
                              v-if="submitted && v$.tag.text.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="v$.tag.text.required.$message">{{
                                v$.tag.text.required.$message
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-end pt-5 mt-3">
                        <b-button
                          variant="light"
                          @click="showModalCreate = false"
                          >Close</b-button
                        >
                        <b-button type="submit" variant="success" class="ms-1"
                          >Create Tag</b-button
                        >
                      </div>
                    </form>
                  </b-modal>
                </div>
                <!-- MODAL CREATE -->

                <!-- MODAL EDIT -->
                <div class="text-sm-end">
                  <b-modal
                    v-model="showModalEdit"
                    :title="`Edit Tag : ${tag.title}`"
                    title-class="text-black font-18"
                    body-class="p-3"
                    hide-footer
                    no-close-on-backdrop
                  >
                    <form @submit.prevent="handleEditSubmit">
                      <div class="row">
                        <div class="col-12">
                          <div class="mb-3">
                            <label for="text">Name</label>
                            <input
                              id="code"
                              v-model="tag.code"
                              v-show="false"
                            />
                            <input
                              id="text"
                              v-model="tag.text"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && v$.tag.text.$error,
                              }"
                            />

                            <div
                              v-if="submitted && v$.tag.text.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="v$.tag.text.required.$message">{{
                                v$.tag.text.required.$message
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-end pt-5 mt-3">
                        <b-button variant="light" @click="showModalEdit = false"
                          >Close</b-button
                        >
                        <b-button type="submit" variant="warning" class="ms-1"
                          >Update Tag</b-button
                        >
                      </div>
                    </form>
                  </b-modal>
                </div>
                <!-- MODAL EDIT -->
              </div>
            </div>
            <div class="table-responsive">
              <vue-good-table
                :search-options="{
                  enabled: true,
                }"
                :pagination-options="{
                  enabled: true,
                  perPage: 10,
                  mode: 'records',
                }"
                :line-numbers="true"
                styleClass="tableOne vgt-table striped"
                :columns="columns"
                :rows="rows"
              >
                <template #table-row="props">
                  <span v-if="props.column.field == 'button'">
                    <b-button
                      size="sm"
                      variant="success"
                      @click="edit(props.formattedRow)"
                    >
                      <i
                        class="mdi mdi-pencil font-size-16 align-middle me-2"
                      ></i>
                      Edit
                    </b-button>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
